<template>
  <div>
    <div class="top_title3">
      <h4>正常工资薪资</h4>
      <h5>{{ period }}</h5>
    </div>
    <el-table :height="contentStyleObj" v-loading="listLoading" :data="list" border fit highlight-current-row style="width: 100%;margin-top: 10px;" show-summary>
      <template #empty>
        <el-empty :image-size="150" description="没有数据"></el-empty>
      </template>
      <el-table-column prop="id" align="center" width="55" type="index"></el-table-column>
      <el-table-column label="姓名" align="center" min-width="90">
        <template #default="scope">
          <span>{{scope.row.name}}</span>
        </template>
      </el-table-column>
      <el-table-column label="费用类型" align="center" min-width="100">
        <template #default="scope">
          <span>{{scope.row.feeType}}</span>
        </template>
      </el-table-column>
      <el-table-column label="应发工资" align="center">
        <el-table-column prop="salary" label="工资" min-width="80">
          <template #default="scope">
            <span>{{scope.row.salary}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="subsidy" label="福利费" min-width="80">
          <template #default="scope">
            <span>{{scope.row.subsidy}}</span>
          </template>
        </el-table-column>
      </el-table-column>
      <el-table-column label="代扣社保" width="250" align="center" @change="changeReckon(scope.row)">
        <el-table-column prop="deductSbPension" label="养老保险" min-width="80">
          <template #default="scope">
            <span>{{scope.row.deductSbPension}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deductSbMedical" label="医疗保险" min-width="80">
          <template #default="scope">
            <span>{{scope.row.deductSbMedical}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="deductSbUnemployment" label="失业保险" min-width="80">
          <template #default="scope">
            <span>{{scope.row.deductSbUnemployment}}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="deductSbIllness" label="大病医疗" min-width="80">
          <template #default="scope">
            <span>{{scope.row.deductSbIllness}}</span>
          </template>
        </el-table-column> -->
        <el-table-column prop="deductSbTotal" label="合计" min-width="60"></el-table-column>
      </el-table-column>
      <el-table-column label="代扣公积金" prop="deductHouseFund" align="center" min-width="100">
        <template #default="scope">
          <span>{{scope.row.deductHouseFund}}</span>
        </template>
      </el-table-column>
      <el-table-column label="其他扣除" prop="deductOther" align="center" min-width="100">
        <template #default="scope">
          <span>{{scope.row.deductOther}}</span>
        </template>
      </el-table-column>
      <el-table-column label="本期代扣个税" prop="deductIncomeTax" align="center" min-width="110">
        <template #default="scope">
          <span>{{scope.row.deductIncomeTax}}</span>
        </template>
      </el-table-column>
      <el-table-column label="实发工资" prop="actualSalary" align="center" min-width="100">
        <template #default="scope">
          <span>{{scope.row.actualSalary}}</span>
        </template>
      </el-table-column>
      <el-table-column label="备注" align="center" min-width="100">
        <template #default="scope">
          <span>{{scope.row.remark}}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <qzf-pagination v-show="total>0" :total="total" v-model:page="listQuery.page" v-model:limit="listQuery.limit" @pagination="getList" />
    </div>
  </div>
</template>

<script>
import { eaSalaryList } from "@/api/salary.js"
export default {
  name:'normal',
  props: {
    comId:{
      type:Number,
      default:0
    },
    period:{
      type:String,
      default:""
    },
    sheetId:{
      type:Number,
      default:0
    },
  },
  data(){
    return {
      list:[],
      listLoading:false,
      listQuery: {
        page: 1,
        limit: 20
      },
      total:0,
    }
  },
  created(){
    this.getList()
    this.contentStyleObj= this.$getHeight(230)
  },
  methods:{
    getList() {
      this.listLoading = true;
      let param = {
        period: this.period,
        comId: this.comId,
      }
      eaSalaryList(param).then(res => {
        this.listLoading = false;
        if(res.data.msg == "success"){
          this.list = res.data.data.list
          this.total = res.data.data.total
          //console.log(this.list);
        }else{
          this.list = []
        }
      });
    },
  }
}
</script>

<style>

</style>